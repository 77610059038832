import { ClientPrintJob, DefaultPrinter, Encoding, JSPrintManager, WSStatus } from 'jsprintmanager'

require('/node_modules/codepage/bits/21866.js');

export default function() {

  JSPrintManager.auto_reconnect = true
  JSPrintManager.start()
  JSPrintManager.WS.onStatusChanged = function() {

    if (JSPrintManager.websocket_status == WSStatus.Open) {
      var cpj = new ClientPrintJob()
      cpj.clientPrinter = new DefaultPrinter()

      console.log(cpj.printerCommandsCodePage)

      cpj.printerCommandsCodePage = Encoding.OEM_Russian_Cyrillic_DOS

      var cmds = []

      cmds.push(new Uint8Array([0x1b, '@'.charCodeAt(0)]))
      // console.log(cmds)

      //set code page 17 => CP808 (Cyrillic)
      // cmds.push(new Uint8Array([0x1b, 0x74, 11]))
      // console.log(cmds)


      cmds.push(convert2('salom'))

      cmds.push(new Uint8Array([0x0a, 0x0a, 0x0a, 0x0a, 0x0a]))

      const encoding = Encoding.OEM_Russian_Cyrillic_DOS
      const text = convert2('Привет Хабр!ҒҲғҳшўЎҚҚ', encoding)
      cmds.push(text)
      console.log('text', cmds)

      cmds.push(new Uint8Array([0x0a, 0x0a, 0x0a, 0x0a, 0x0a]))

      cmds.push(new Uint8Array([0x1b, 0x6d]))
      // console.log(cmds)

      // Get the total length of all arrays.
      let length = 0
      cmds.forEach(item => {
        length += item.length
      })

      // Create a new array with total length and merge all source arrays.
      let mergedCmds = new Uint8Array(length)
      let offset = 0
      cmds.forEach(item => {
        mergedCmds.set(item, offset)
        offset += item.length
      })

      cpj.binaryPrinterCommands = mergedCmds
      console.log(mergedCmds)

      cpj.sendToClient()

    }
  }
}

function convert(str, encoding = Encoding.OEM_Russian_Cyrillic_DOS) {

  return new Uint8Array(cptable.utils.encode(encoding, str))
}

function convert2(str, encoding = Encoding.OEM_Russian_Cyrillic_DOS) {


  str = str.replace('Ғ', 'Г')
  str = str.replace('ғ', 'г')
  str = str.replace('Ҳ', 'Х')
  str = str.replace('ҳ', 'х')
  str = str.replace('Қ', 'К')
  str = str.replace('қ', 'к')
  // str.replace('Ў', 'У')
  // str.replace('ў', 'у')
  // 'Привет Хабр!ҒҲғҳшўЎҚҚ'


  return new Uint8Array(cptable.utils.encode(encoding, str))
}

