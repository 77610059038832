<template>
	<v-dialog
		v-model='show'
		@keydown.esc='close()'
		@click:outside='close()'
		@keydown.enter='!printDisabled && printCheck()'
		max-width='1000px'
		width='500px'
	>
		<v-card>
			<v-card-title>
				<span class='headline'>Chek chiqarish</span>
			</v-card-title>
			<v-card-text>
				<v-container>
					<v-row>

						<v-col cols='12'>

							<v-alert
								v-if='isWSClosed'
								type='error'
							>
								<div>JSPrintManager o'rnatilmagan yoki ishga tushmagan!</div>
								<div>Shu havoladan orqali kirib yuklab oling: <a
									href='https://www.neodynamic.com/downloads/jspm/?v=5' target='_blank'>www.neodynamic.com/downloads/jspm</a>
								</div>
							</v-alert>

							<v-alert
								v-if='isWSBlocked'
								type='error'
							>
								<div>JSPrintManager sozlanmasiga kirib "{{ siteHost }}" shu manzilni blokldan
									chiqaring!
								</div>
							</v-alert>

							<v-alert
								v-if='isWSOpened && printer === null'
								type='warning'
							>
								Chop etish uchun printerni tanlang
							</v-alert>

							<v-select
								v-model='printer'
								:items='printers'
								label='Printer'
								outlined
							/>

						</v-col>

					</v-row>
				</v-container>
			</v-card-text>

			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn color='gray' outlined @click='close()'>Bekor qilish</v-btn>

				<v-btn
					color='primary'
					type='button'
					@click='!printDisabled && printCheck()'
					:disabled='printDisabled'
				>
					<v-icon
						class='loading-animation'
					>
						{{ icons.mdiPrinter }}
					</v-icon>
					Chop etish
				</v-btn>
			</v-card-actions>

		</v-card>

	</v-dialog>
</template>

<script>
import { mdiPrinter } from '@mdi/js'
import { computed, ref, watch } from '@vue/composition-api'
import store from '@/store'
import {
	ClientPrintJob,
	DefaultPrinter,
	Encoding,
	InstalledPrinter,
	JSPrintManager,
	WSStatus,
} from 'jsprintmanager'
import PrintManager, { makeBinaryCommands } from './print/PrintManager'
import moment from 'moment'
import numeral from 'numeral'

moment.locale('uz-latn')

export default {
	name: 'CheckDialog.vue',
	setup() {

		let data = null

		const siteHost = window.location.host

		// show, hide
		const show = ref(false)

		const open = (input) => {
			data = input
			show.value = true

			startPrintManager()
		}

		const close = () => {
			show.value = false

			//buni ishlatsa qayta ochish sekinlashib qoladi
			// stopPrintManager()
		}

		const {
			printers,
			printer,

			isWSOpened,
			isWSClosed,
			isWSBlocked,

			startPrintManager,
			stopPrintManager,
			print,
		} = PrintManager(show)

		const printDisabled = computed(() => {
			return !(printer.value !== null && isWSOpened.value)
		})

		const printCheck = () => {

			const month = moment(data.payment.date).format('MMMM YYYY')

			let header = []
			if (store.state.app.branch.check_header) {
				header.push([0x1b, '!'.charCodeAt(0), 0x38]) //Emphasized + Double-height + Double-width mode selected (ESC ! (8 + 16 + 32)) 56 dec => 38 hex
				header.push(store.state.app.branch.check_header)
				header.push('\n')
				header.push('\n')
			}

			let footer = []
			if (store.state.app.branch.check_phone) {

				footer.push('\n')
				footer.push('\n')
				footer.push(`Murojaat uchun: ${store.state.app.branch.check_phone}`)
			}

			let easyCommands = [

				//Initializes the printer (ESC @)
				[0x1b, '@'.charCodeAt(0)],

				//set code page 17 => CP808 (Cyrillic)
				//[0x1b, 0x74, 11], //Selects 12 cpi (ESC t 11)

				...header,

				[0x1b, '!'.charCodeAt(0), 0x00], //Character font A selected (ESC ! 0)
				`Talaba:      ${data.payment.student.full_name}`,
				'\n',
				`Qaysi oyga:  ${month}`,
				'\n',

				// `Oylik to\'lov:${number_format(model.payment.amount)}`,
				// '\n',
				`To\'ladi:     ${number_format(data.amount)}`,
				'\n',

				(data.cashbox ? `To\'lov turi: ${data.cashbox.name}\n` : ''),

				`Ustoz:       ${data.payment.group.teacher.full_name}`,
				'\n',
				`Guruh:       ${data.payment.group.number}`,
				'\n',
				`Fan:         ${data.payment.group.subject.name}`,
				'\n',
				`Sana:        ${moment(data.date).format('DD.MM.YYYY')}`,
				'\n',
				'\n',
				`             ${moment().format('DD.MM.YYYY HH:mm:ss')}`,
				...footer,
				'\n', '\n',
				'\n', '\n',
				'\n', '\n',

				//cut paper
				[0x1b, 0x6d],
			]

			const binaryCommands = makeBinaryCommands(easyCommands)

			print(binaryCommands)
		}


		function number_format(value) {

			return value !== null && value !== '' ? numeral(String(value).replace('.', ',')).format('0,0') : '—'
		}


		return {
			show,
			open,
			close,

			printers,
			printer,
			printCheck,
			printDisabled,
			siteHost,

			isWSOpened,
			isWSClosed,
			isWSBlocked,

			icons: {
				mdiPrinter,
			},
		}
	},
}
</script>

<style scoped>

</style>