import { ClientPrintJob, DefaultPrinter, Encoding, JSPrintManager, WSStatus } from 'jsprintmanager'

// import cptable from 'codepage'

import print4 from './print4'
import print3 from './print3'
import receipt from './receipt'


const print1 = function(data) {

	console.log(data)

	JSPrintManager.auto_reconnect = true
	JSPrintManager.start()
	JSPrintManager.WS.onStatusChanged = function() {

		if (JSPrintManager.websocket_status == WSStatus.Open) {
			var cpj = new ClientPrintJob()
			cpj.clientPrinter = new DefaultPrinter()

			// var cmds =  "\xef\xbb\xbf"; //UTF8 BOM!
			// cmds += "^XA";
			// cmds += "^CWZ,E:TT0003M_.FNT^FS";
			// cmds += "^FO10,50^CI28^AZN,50,50^FDUNICODE using CI28 UTF-8 encoding^FS";
			// cmds += "^FO010,160^CI28^AZN,50,40^FD- Roman: ABCDEFGHIJKLMNOPQRSTUVWXYZ^FS";
			// cmds += "^FO010,230^CI28^AZN,50,40^FD- Cyrillic: ЁЂЃЄЅІЇЈЉЊЋЌЎЏАБВГДЕЖЗИЙКЛМН^FS";
			// cmds += "^FO010,300^CI28^AZN,50,40^FD- Eastern: ŠŚŤŽŹŁĄŞŻĽľŔÁÂĂÄĹĆÇČÉĘËĚÍÎĎ^FS";
			// cmds += "^FO010,370^CI28^AZN,50,40^FD- Greek: ΆΈΉΊΌΐΑΒΓΔΕΖΗΘΙΚΛΜΝΞΟΠΡΣΤΥΦΧΨΩΪΫ^FS";
			// cmds += "^FO010,440^CI28^AZN,50,40^FD- Turkish: ÀÁÂÃÄÅÆÇÈÉÊËÌÍÎĞÑÒÓÔÕÖ×ØÙÚÛÜİŞ^FS";
			// cmds += "^PA1,1,1,1^FS"; //^PA command is mandatory for RTL Languages like Arabic & Hebrew
			// cmds += "^FO010,510^CI28^AZN,50,40^FD- Arabic: زيبرة  تكنوليجيز اوربا المحدودة^FS";
			// cmds += "^PQ1";
			// cmds += "^XZ";


			let cmd = '\x1B' + '\x74' + '17'
			cmd += 'To\'lov turi:'
			cmd += newLine + newLine
			cmd += newLine + newLine
			// cmd += utf8_to_866('Привет Хабр!');
			cmd += newLine + newLine
			cmd += newLine + newLine
			cmd += newLine + newLine
			cmd += newLine + newLine
			cmd += '\x1B' + '\x6D'

			cpj.printerCommands = cmd
			cpj.sendToClient()

		}
	}
}

var esc = '\x1B' //ESC byte in hex notation
var newLine = '\x0A' //LF byte in hex notation


export function print2(data) {

	console.log('print2', data)

	JSPrintManager.auto_reconnect = true
	JSPrintManager.start()
	JSPrintManager.WS.onStatusChanged = function() {

		if (JSPrintManager.websocket_status == WSStatus.Open) {

			console.log('salom')

			var cpj = new ClientPrintJob()
			cpj.clientPrinter = new DefaultPrinter()


			var cmds = esc + '@' //Initializes the printer (ESC @)
			// // cmds += esc + '!' + '\x38'; //Emphasized + Double-height + Double-width mode selected (ESC ! (8 + 16 + 32)) 56 dec => 38 hex
			// cmds += esc + '!' + '\x38' //Emphasized + Double-height mode selected (ESC ! (16 + 8)) 24 dec => 18 hex
			// cmds += 'Header' //text to print
			// cmds += newLine + newLine
			// cmds += esc + '!' + '\x00' //Character font A selected (ESC ! 0)
			//set code page 17 (Russian)
			cmds += esc + 't' + '\x00'
			// cmds += '\x1B'+ '\x74' + '\xBF'
			// cmds += '\xef\xbb\xbf' //UTF8 BOM!
			cmds += `Привет Хабр!`
			cmds += newLine + newLine
			cmds += newLine + newLine
			cmds += newLine + newLine
			cmds += '\x1B' + '\x6D'
			cpj.printerCommands = cmds

			cpj.sendToClient()

		}
	}
}


// export function print3() {
//
//
// 	JSPrintManager.auto_reconnect = true
// 	JSPrintManager.start()
// 	JSPrintManager.WS.onStatusChanged = function() {
//
// 		if (JSPrintManager.websocket_status == WSStatus.Open) {
// 			var cpj = new ClientPrintJob()
// 			cpj.clientPrinter = new DefaultPrinter()
//
// 			var cmds = []
//
// 			// cmds = cmds.concat(Array.from(new Uint8Array(['@'.charCodeAt(0)])))
// 			// console.log(cmds)
// 			cmds = cmds.concat(Array.from(new Uint8Array([0x1b, 0x74, 17])))
// 			cpj.binaryPrinterCommands = cmds
//
// 			var text = utf8_to_866('\n\nПривет Хабр!\n\n\n')
// 			cmds = cmds.concat(Array.from(text))
// 			console.log(cmds)
// 			// cpj.binaryPrinterCommands = cmds
//
// 			// cmds = cmds.concat(Array.from(new Uint8Array([0x1b, 0x6d])))
// 			// console.log(cmds)
// 			cpj.binaryPrinterCommands = cmds
//
// 			// cmds2 += new Uint8Array([0xA, 0xA, 0xA])
// 			// cmds2 = new Uint8Array([0x1b, 0x6d])
// 			// cpj.binaryPrinterCommands = cmds2
//
// 			cpj.sendToClient()
//
// 		}
// 	}
// }



export default {
	print1,
	print4,
	print3,
	receipt,
}

// var my_file = new PrintFile('/images/penguins300dpi.jpg', FileSourceType.URL, 'MyFile.jpg', 1)
// cpj.files.push(my_file)