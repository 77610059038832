import { ClientPrintJob, DefaultPrinter, Encoding, JSPrintManager, WSStatus } from 'jsprintmanager'
import moment from 'moment'
import numeral from 'numeral'
import store from '@/store'

moment.locale('uz-latn')
// require('/node_modules/codepage/bits/21866.js');
//62306 Cyrillic Uzbek

export default function(data) {

	JSPrintManager.auto_reconnect = true
	JSPrintManager.start()
	JSPrintManager.WS.onStatusChanged = function() {

		if (JSPrintManager.websocket_status == WSStatus.Open) {
			var cpj = new ClientPrintJob()
			cpj.clientPrinter = new DefaultPrinter()

			// console.log(cpj.printerCommandsCodePage)
			// cpj.printerCommandsCodePage = Encoding.OEM_Russian_Cyrillic_DOS


			cpj.binaryPrinterCommands = makeBinaryCommands(data)

			cpj.sendToClient()
		}
	}
}

export function makeBinaryCommands(data) {
	let cmds = []

	//Initializes the printer (ESC @)
	cmds.push(new Uint8Array([0x1b, '@'.charCodeAt(0)]))

	//set code page 17 => CP808 (Cyrillic)
	// cmds.push(new Uint8Array([0x1b, 0x74, 11]))

	//view
	const commands = view(data)
	commands.forEach(command => {

		if (command === '\n') {
			command = new Uint8Array([0x0a])
		} else if (typeof command === 'string') {
			command = convert(command)
		} else if (command === '') {
			return
		}

		cmds.push(command)
	})

	//cut
	cmds.push(new Uint8Array([0x1b, 0x6d]))
	// console.log(cmds)

	// Get the total length of all arrays.
	let length = 0
	cmds.forEach(item => {
		length += item.length
	})

	// Create a new array with total length and merge all source arrays.
	let mergedCmds = new Uint8Array(length)
	let offset = 0
	cmds.forEach(item => {
		mergedCmds.set(item, offset)
		offset += item.length
	})

	return mergedCmds
}

function view(data) {

	const month = moment(data.payment.date).format('MMMM YYYY')

	let header = []
	if (store.state.app.branch.check_header) {
		header.push([0x1b, '!'.charCodeAt(0), 0x38]) //Emphasized + Double-height + Double-width mode selected (ESC ! (8 + 16 + 32)) 56 dec => 38 hex
		header.push(store.state.app.branch.check_header)
		header.push('\n')
		header.push('\n')
	}

	let footer = []
	if (store.state.app.branch.check_phone) {

		footer.push('\n')
		footer.push('\n')
		footer.push(`Murojaat uchun: ${store.state.app.branch.check_phone}`)
	}

	let commands = [
		...header,
		[0x1b, '!'.charCodeAt(0), 0x00], //Character font A selected (ESC ! 0)
		`Talaba:      ${data.payment.student.full_name}`,
		'\n',
		`Qaysi oyga:  ${month}`,
		'\n',

		// `Oylik to\'lov:${number_format(data.payment.amount)}`,
		// '\n',
		`To\'ladi:     ${number_format(data.amount)}`,
		'\n',

		(data.cashbox ? `To\'lov turi: ${data.cashbox.name}\n` : ''),

		`Ustoz:       ${data.payment.group.teacher.full_name}`,
		'\n',
		`Guruh:       ${data.payment.group.number}`,
		'\n',
		`Fan:         ${data.payment.group.subject.name}`,
		'\n',
		`Sana:        ${moment(data.date).format('DD.MM.YYYY')}`,
		'\n',
		'\n',
		`             ${moment().format('DD.MM.YYYY HH:mm:ss')}`,
		...footer,
		'\n', '\n',
		'\n', '\n',
		'\n', '\n',
	]

	return commands
}

function number_format(value) {

	return value !== null && value !== '' ? numeral(String(value).replace('.', ',')).format('0,0') : '—'
}

function convert2(str, encoding = Encoding.OEM_Russian_Cyrillic_DOS) {

	return new Uint8Array(cptable.utils.encode(encoding, str))
}

function convert(str, encoding = Encoding.OEM_Russian_Cyrillic_DOS) {


	str = str.replace('Ғ', 'Г')
	str = str.replace('ғ', 'г')
	str = str.replace('Ҳ', 'Х')
	str = str.replace('ҳ', 'х')
	str = str.replace('Қ', 'К')
	str = str.replace('қ', 'к')
	// str.replace('Ў', 'У')
	// str.replace('ў', 'у')


	return new Uint8Array(cptable.utils.encode(encoding, str))
}

