import { ClientPrintJob, DefaultPrinter, Encoding, JSPrintManager, WSStatus } from 'jsprintmanager'

var esc = '\x1B' //ESC byte in hex notation
var newLine = '\x0A' //LF byte in hex notation

require('/node_modules/codepage/bits/855.js');

export default function() {

	JSPrintManager.auto_reconnect = true
	JSPrintManager.start()
	JSPrintManager.WS.onStatusChanged = function() {

		if (JSPrintManager.websocket_status == WSStatus.Open) {

			console.log('salom')

			var cpj = new ClientPrintJob()
			cpj.clientPrinter = new DefaultPrinter()

			cpj.printerCommandsCodePage = Encoding.OEM_Russian_Cyrillic_DOS;
			//arabic
			cpj.printerCommandsCodePage = Encoding.Unicode_UTF_8;
			cpj.printerCommandsCodePage = Encoding.OEM_Cyrillic_primarily_Russian;


			var cmds = esc + '@' //Initializes the printer (ESC @)
			// // cmds += esc + '!' + '\x38'; //Emphasized + Double-height + Double-width mode selected (ESC ! (8 + 16 + 32)) 56 dec => 38 hex
			// cmds += esc + '!' + '\x38' //Emphasized + Double-height mode selected (ESC ! (16 + 8)) 24 dec => 18 hex
			// cmds += 'Header' //text to print
			// cmds += newLine + newLine
			// cmds += esc + '!' + '\x00' //Character font A selected (ESC ! 0)
			//set code page 17 (Russian)
			// cmds += esc + 't' + '\x00'
			// cmds += '\x1B'+ '\x74' + '\xBF'
			// cmds += '\xef\xbb\xbf' //UTF8 BOM!
			cmds += `asdПривет Хабр!ғҳғ123`
			cmds += newLine + newLine
			cmds += newLine + newLine
			cmds += newLine + newLine
			cmds += '\x1B' + '\x6D'
			cpj.printerCommands = cmds

			cpj.sendToClient()

		}
	}
}