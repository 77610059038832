import { ref } from '@vue/composition-api'

import {
	ClientPrintJob,
	// DefaultPrinter,
	Encoding,
	InstalledPrinter,
	JSPrintManager,
	WSStatus,
} from 'jsprintmanager'

export default function(viewOpened) {

	const isWSClosed = ref(false)
	const isWSBlocked = ref(false)
	const isWSOpened = ref(false)

	function startPrintManager(port = null) {

		port = port || parseInt(localStorage.getItem('JSPrintManager:port')) || 25443

		setPrinterPort(port)

		JSPrintManager.auto_reconnect = false
		JSPrintManager.start(true, 'localhost', port)

		JSPrintManager.WS.onStatusChanged = () => {


			isWSClosed.value = JSPrintManager.websocket_status === WSStatus.Closed
			isWSBlocked.value = JSPrintManager.websocket_status === WSStatus.Blocked
			isWSOpened.value = JSPrintManager.websocket_status === WSStatus.Open

			if (isWSOpened.value) getPrinters()
			if (isWSClosed.value && viewOpened.value === true) connectToNextPort()
		}
	}

	function stopPrintManager() {

		JSPrintManager.stop()
	}

	function restartPrintManager(port) {
		stopPrintManager()

		setTimeout(() => {
			startPrintManager(port)
		}, 500)
	}

	const print = (commands) => {
		const cpj = new ClientPrintJob()

		// cpj.clientPrinter = new DefaultPrinter()
		if (!printer.value) return alert('Printerni tanlang')

		cpj.clientPrinter = new InstalledPrinter(printer.value)

		if (typeof commands === 'string')
			cpj.printerCommands = commands
		else
			cpj.binaryPrinterCommands = commands

		cpj.sendToClient()

		setLastUsedPrinter()
	}

	/**********************************************************************************************
	 * Printer port
	 */
	const printerPort = ref(null)
	const allowedPorts = [25443, 24443]

	function setPrinterPort(port) {
		printerPort.value = port

		//set port to local storage
		localStorage.setItem('JSPrintManager:port', String(port))
	}

	function connectToNextPort() {

		const port = getNextPort()
		setPrinterPort(port)

		restartPrintManager(port)
	}

	function getNextPort() {
		const port = printerPort.value
		let nextPort

		const index = allowedPorts.indexOf(port)

		if (index === -1)
			nextPort = allowedPorts[0]
		else if (index === allowedPorts.length - 1)
			nextPort = allowedPorts[0]
		else
			nextPort = allowedPorts[index + 1]

		return nextPort
	}

	/*
	 * Printer port
	 **********************************************************************************************/


	/**********************************************************************************************
	 * printers
	 */
	const printers = ref([])
	const printer = ref(null)

	const getPrinters = () => {

		JSPrintManager.getPrinters().then(printerItems => {

			const options = []

			for (let i = 0; i < printerItems.length; i++) {
				options.push({
					text: printerItems[i],
					value: printerItems[i],
				})
			}

			printers.value = options

			if (printers.value.indexOf(printer.value) === -1)
				printer.value = null

			if (!printer.value) {
				options.length === 1 ? printer.value = options[0].value : setFromLastUsedPrinter()
			}
		})

	}

	function setLastUsedPrinter() {
		localStorage.setItem('lastUsedPrinter', printer.value)
	}

	function setFromLastUsedPrinter() {
		const lastUsedPrinter = localStorage.getItem('lastUsedPrinter')
		if (lastUsedPrinter && printers.value.indexOf(lastUsedPrinter) !== -1) {
			printer.value = lastUsedPrinter
		}
	}

	/*
	 * printers
	 **********************************************************************************************/


	return {
		startPrintManager,
		stopPrintManager,

		printers,
		printer,

		isWSOpened,
		isWSClosed,
		isWSBlocked,

		print,
	}

}


export function makeBinaryCommands(easyCommands, init = false, cut = false, codePage = null) {
	let partCommands = []

	if (init) {
		//Initializes the printer (ESC @)
		partCommands.push(new Uint8Array([0x1b, '@'.charCodeAt(0)]))
	}

	if (codePage) {
		//set code page 17 => CP808 (Cyrillic)
		//todo: buni tog'riligiga tekshirish kerak
		partCommands.push(new Uint8Array([0x1b, 0x74, 11]))
	}

	easyCommands.forEach(command => {

		if (command === '\n') {
			command = new Uint8Array([0x0a])
		} else if (typeof command === 'string') {
			command = convertStringToBinary(command)
		} else if (command === '') {
			return
		}

		partCommands.push(command)
	})

	if (cut) {
		//cut paper
		partCommands.push(new Uint8Array([0x1b, 0x6d]))
	}

	return mergeBinaryCommands(partCommands)
}

function mergeBinaryCommands(commands) {

	// Get the total length of all arrays.
	let length = 0
	commands.forEach(item => {
		length += item.length
	})

	// Create a new array with total length and merge all source arrays.
	let mergedCommands = new Uint8Array(length)
	let offset = 0

	commands.forEach(item => {
		mergedCommands.set(item, offset)
		offset += item.length
	})

	return mergedCommands
}

function convertStringToBinary(str, encoding = Encoding.OEM_Russian_Cyrillic_DOS) {

	str = str.replace('Ғ', 'Г')
	str = str.replace('ғ', 'г')
	str = str.replace('Ҳ', 'Х')
	str = str.replace('ҳ', 'х')
	str = str.replace('Қ', 'К')
	str = str.replace('қ', 'к')
	// str.replace('Ў', 'У')
	// str.replace('ў', 'у')

	return new Uint8Array(cptable.utils.encode(encoding, str))
}
